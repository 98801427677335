const QUERIES = {
  USERS_LIST: 'users-list',
  ORGANISATIONS_LIST: 'organisations-list',
  WEBSITES_LIST: 'websites-list',
  PAGES_LIST: 'pages-list',
  POSTS_LIST: 'posts-list',
  POSTDROPPERS_LIST: 'postdroppers-list',
  SUBJECTS_LIST: 'subjects-list',
  CONTACTS_LIST: 'contacts-list',
  COUNTRIES_LIST: 'countries-list',
  WEBHOOKS_LIST: 'webhooks_list',
  ANIMATIONS_LIST: 'animations_list',
  COMPONENTS_LIST: 'components_list',
  POSTTYPES_LIST: 'posttypes_list',
  SOCIALS_LIST: 'socials_list',
  SETTINGS_LIST: 'settings_list',
  BLOCKS_LIST: 'blocks_list',
  WEBHOOKSOUT_LIST: 'webhooksout-list',
  HELPTEXTS_LIST: 'helptexts-list',
  ADDRESSES_LIST: 'addresses-list',
  MAILINGS_LIST: 'mailings-list',
  UPLOADS_LIST: 'uploads-list'
}

export {QUERIES}
