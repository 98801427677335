import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from 'src/_config/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from 'src/dashboard/DashboardWrapper'
import {getCSSVariableValue} from 'src/_config/assets/ts/_utils'
import {WithChildren} from 'src/_config/helpers';

import {AcceptTerms} from 'src/auth/components/AcceptTerms';
import {VerifyEmail} from 'src/auth/components/VerifyEmail';
import {VerifyPhone} from 'src/auth/components/VerifyPhone';
import {Invitations} from 'src/auth/components/Invitations';

import {AccountPage} from 'src/account/AccountPage';


const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('src/app/user-management/UsersPage'))
  const OrganisationsPage = lazy(() => import('src/app/organisation-management/OrganisationsPage'))
  const WebsitesPage = lazy(() => import('src/app/website-management/WebsitesPage'))
  const WebhooksOutPage = lazy(() => import('src/app/webhook-management/WebhooksPage'))
  const PagesPage = lazy(() => import('src/app/page-management/PagesPage'))
  const BlocksPage = lazy(() => import('src/app/block-management/BlocksPage'))
  const PostsPage = lazy(() => import('src/app/post-management/PostsPage'))
  const PostdroppersPage = lazy(() => import('src/app/postdropper-management/PostdroppersPage'))
  const SubjectsPage = lazy(() => import('src/app/subject-management/SubjectsPage'))
  const ContactsPage = lazy(() => import('src/app/contact-management/ContactsPage'))
  const CountriesPage = lazy(() => import('src/admin/country-management/CountriesPage'))
  const WebhooksPage = lazy(() => import('src/admin/webhook-management/WebhooksPage'))
  const ComponentsPage = lazy(() => import('src/admin/component-management/ComponentsPage'))
  const AnimationsPage = lazy(() => import('src/admin/animation-management/AnimationsPage'))
  const PosttypesPage = lazy(() => import('src/admin/posttype-management/PosttypesPage'))
  const SocialsPage = lazy(() => import('src/admin/social-management/SocialsPage'))
  const SettingsPage = lazy(() => import('src/admin/setting-management/SettingsPage'))
  const HelptextsPage = lazy(() => import('src/admin/helptext-management/HelptextsPage'))
  const AddressesPage = lazy(() => import('src/app/address-management/AddressesPage'))
  const MailingsPage = lazy(() => import('src/app/mailing-management/MailingsPage'))
  const AnimationsCustomPage = lazy(() => import('src/app/animation-management/AnimationsPage'));
  const UploadsPage = lazy(() => import('src/app/upload-management/UploadsPage'))
      

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard/*' element={<DashboardWrapper />} />

        <Route  path='verifyemail' element={<VerifyEmail />} />
        <Route  path='verifyphone' element={<VerifyPhone />} />
        <Route  path='acceptterms' element={<AcceptTerms />} />
        <Route  path='invitations' element={<Invitations />} />
        <Route  path='account/*' element={<AccountPage />} />

        {/* Lazy Modules */}
        <Route
          path='app/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='app/organisation-management/*'
          element={
            <SuspensedView>
              <OrganisationsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/website-management/*'
          element={
            <SuspensedView>
              <WebsitesPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/webhook-management/*'
          element={
            <SuspensedView>
              <WebhooksOutPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/page-management/*'
          element={
            <SuspensedView>
              <PagesPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/block-management/*'
          element={
            <SuspensedView>
              <BlocksPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/post-management/*'
          element={
            <SuspensedView>
              <PostsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/postdropper-management/*'
          element={
            <SuspensedView>
              <PostdroppersPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/subject-management/*'
          element={
            <SuspensedView>
              <SubjectsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/upload-management/*'
          element={
            <SuspensedView>
              <UploadsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/animation-management/*'
          element={
            <SuspensedView>
              <AnimationsCustomPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/contact-management/*'
          element={
            <SuspensedView>
              <ContactsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/address-management/*'
          element={
            <SuspensedView>
              <AddressesPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='app/mailing-management/*'
          element={
            <SuspensedView>
              <MailingsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='admin/country-management/*'
          element={
            <SuspensedView>
              <CountriesPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='admin/webhook-management/*'
          element={
            <SuspensedView>
              <WebhooksPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='admin/component-management/*'
          element={
            <SuspensedView>
              <ComponentsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='admin/animation-management/*'
          element={
            <SuspensedView>
              <AnimationsPage />
            </SuspensedView>
          }
        /> 
        <Route
          path='admin/posttype-management/*'
          element={
            <SuspensedView>
              <PosttypesPage />
            </SuspensedView>
          }
        />         
        <Route
          path='admin/social-management/*'
          element={
            <SuspensedView>
              <SocialsPage />
            </SuspensedView>
          }
        />         
        <Route
          path='admin/setting-management/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />         
        <Route
          path='admin/helptext-management/*'
          element={
            <SuspensedView>
              <HelptextsPage />
            </SuspensedView>
          }
        />         
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
