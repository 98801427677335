import {useState, useEffect} from 'react'
import clsx from 'clsx';
import { fieldValidation, getObjectValue, setObjectValue, getValidation, KTIcon } from 'src/_config/helpers';
import {PasswordMeterComponent} from 'src/_config/assets/ts/components'

export const InputPassword = ( props ) => {
  // props overnemen
  const [values, setValues] = props.values;
  const label = props.label || 'Label';
  const link = props.link;
  const linkTitle = props.linkTitle || '';
  const placeholder = props.placeholder || "";
  const name = props.name;
  const type = props.type || "password";
  const cols = props.cols;
  const disabled = props.disabled;
  const onFocus = props.onFocus || null;
  const onBlur = props.onBlur || null;
  const validation = props.validation || "";

  const min = getValidation( validation, "min", 0 );
  const max = getValidation( validation, "max", undefined );
  const value = getObjectValue( values, name, type == "number" ? min : "" );

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

 
  // classes instellen
  const clsCols = cols ? 'col-' + cols : 'col';
  const cls = validation.includes('required') ? 'required' : '';

  // lokale status
  const [touched, setTouched] = useState< boolean >( false );

  const textChange = (data) => {
    setTouched( true );
    setValues( setObjectValue( values, name, data ) );
  }

  const textFocus = ( ) => {
    setTouched( true );
    if (onFocus) onFocus();
  }

  // valideren
  const { valid, message } =  fieldValidation( { name, touched, value, validation } );

  return (
    <div className={ clsCols + ' fv-row '} data-kt-password-meter='true'>
      <label className={ cls + ' fw-bold fs-6 mb-2'}>{label}</label>
      {link && link != "off" ?
        <a
          href={link}
          title={linkTitle}
          target='_blank'
          className='btn btn-icon btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='eye' className='fs-1' />
        </a>
      : null }
      <input
        placeholder={placeholder}
        name={name}
        onChange={ (e) => textChange(e.target.value) }
        type={ type }
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': !valid },
          {
            'is-valid': touched && valid,
          }
        )}
        value={value}
        min={min}
        max={max}
        autoComplete='off'
        disabled={ disabled }
        onFocus={ textFocus }
        onBlur={ onBlur }
      />
      {/* begin::Meter */}
      <div
        className='d-flex align-items-center mb-3'
        data-kt-password-meter-control='highlight'
      >
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
      </div>
      {/* end::Meter */}
      { touched && !valid && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{message}</span>
          </div>
        </div>
      )}
    </div>
  )
}
