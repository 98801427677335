import {useState} from 'react'
import { toAbsoluteUrl, getValidation, getObjectValue, setObjectValue, KTIcon, bytesText } from 'src/_config/helpers'
import { imageResizer } from 'src/tools/imageResizer'


export const InputImage = ( props ) => {
  // props overnemen
  const [values, setValues] = props.values;
  const label = props.label || 'Afbeelding';
  const link = props.link || 'https://www.fotobewerken.eu/editor/'
  const linkTitle = props.linkTitle || '';
  const name = props.name;
  const validation = props.validation || "";
  const cols = props.cols;
  const disabled = props.disabled;
  const preview = props.preview || '';
  const value = getObjectValue( values, name, "" );
  const onFocus = props.onFocus || null;
  const onBlur = props.onBlur || null;
  const accept = props.accept || '.png, .jpg, .jpeg, .webp, .ico, .svg';
  const max = props.max || null;

  // waardes bepalen
  var width = getValidation( validation, "width", 1000);
  var height = getValidation( validation, "height", 750);

  // classen instellen
  const clsCols = cols ? 'col-' + cols : 'col';
  const required = validation.includes('required');
  const cls = required ? 'required' : '';

  // preview afmetingen
  var showWidth = 125;
  var showHeight = 125;
  switch (preview) {
    case 'square':
      showWidth = 350;
      showHeight = 350;
      break;
    case 'smallSquare':
      showWidth = 200;
      showHeight = 200;
      break;
    case 'landscape':
      showWidth = 300;
      showHeight = 225; 
      break;
    case 'smallLandscape':
      showWidth = 200;
      showHeight = 150; 
      break;
    case 'panorama':
      showWidth = 300;
      showHeight = 170; 
      break;
    case 'smallPanorama':
      showWidth = 200;
      showHeight = 115; 
      break;
    case 'portrait':
      showWidth = 225;
      showHeight = 300; 
      break;
    case 'smallPortrait':
      showWidth = 150;
      showHeight = 200; 
      break;
    case 'favicon':
      showWidth = 32;
      showHeight = 32; 
      break;
    case 'logo':
      showWidth = 125;
      showHeight = 125;
      break;      
    default:
      if (width < 300) {
        showWidth = width;
        showHeight = height;
      }
      else {
        showWidth = 300;
        showHeight = Math.round( height * showWidth / width ); 
      }
      break;
  }

  // lokale status
  const [error, setError] = useState< any >( '' );
  const [touched, setTouched] = useState< boolean >( false );

  // achtergrond voor de afbeelding
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg');


  const imageChange = (data) => {
    setTouched( true );
    setValues( setObjectValue( values, name, data ) );
  }

  const imageFocus = ( ) => {
    setTouched( true );
    if (onFocus) onFocus();
  }

  // valideren
  var valid = true;
  var message = "";

  if ( required && !value ){
    if (error) {
      valid = false;
      message = error;
    }
    else {
      message = "verplicht veld";
    }
  }
  else {
    if ( error ){
      valid = false;
      message = error;
    }
  }

  var lblSize = width + " bij " + height + " pixels";
  if ( width == 1 && height == 1 ) lblSize = "Afbeelding wordt niet geresized";
  if (max ) lblSize += ", max " + bytesText(max);

  return (
    <div className={clsCols}>
        <label className={ cls + ' d-block fw-bold fs-6 mb-5'}>{label}
          {link && link != "off" ?
            <a
              href={link}
              title={linkTitle}
              target='_blank'
              className='btn btn-icon btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='eye' className='fs-1' />
            </a>
          : null }
        </label>

        <div
          className='image-input image-input-outline'
          data-kt-image-input='true'
          style={{backgroundImage: `url('${blankImg}')`}}
        >
          <div
            className={'image-input-wrapper w-' + showWidth + 'px h-' + showHeight + 'px'}
            style={{backgroundImage: `url('${value}')`}}
          ></div>

          { disabled ? null :
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Afbeelding uploaden'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input 
                type='file' 
                name={name} 
                accept={accept} 
                onFocus={ imageFocus }
                onBlur={ onBlur }
                onChange={(e) => {
                  if (!e.target.files) return;
                  const file = e.target.files[0];
                  if (max && file.size > max ){
                    imageChange( value );
                    setError( "De gekozen afbeelding is te groot en is geweigerd" );
                    return false;
                  }
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                      const image = new Image();
                      if ( e.target && typeof e.target.result === 'string' ){
                        image.src = e.target.result;
                        image.onload = () => {
                          const { height: h, width: w } = image;
                          if ( height == 1 && width == 1 ){
                            height = h;
                            width = w;
                          }
                          if ( h < height * 0.98  || w < width * 0.98 ) {
                            imageChange( value );
                            setError( "De gekozen afbeelding is te klein en is geweigerd" );
                            return false;
                          }
                          const ratio = height / width;
                          const r = h / w;
                          if ( r > ratio * 1.02 || r < ratio * 0.98 ) {
                            imageChange( value );
                            setError( "De gekozen afbeelding heeft de verkeerde lengte / breedte verhouding" );
                            return false;
                          }
                          setError( '' );
                          if (file.type == "image/svg+xml") {
                            //console.log(reader.result);
                            imageChange(reader.result);
                          }
                          else {
                            imageResizer(file, imageChange, width, height);
                          }
                        };
                      }
                    }


                }}
              />
            </label>
          }

          { required ?
            null :
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Afbeelding verwijderen'
              onClick={ () => imageChange('') }
            >
              <i className='bi bi-x fs-2'></i>
            </span>
          }
        </div>

        <div className='form-text'>{lblSize}</div>

        { touched && !valid && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{message}</span>
            </div>
          </div>
        )}
      </div>
    )
}
