import {useState} from 'react'
import clsx from 'clsx'
import { fieldValidation, getObjectValue, setObjectValue, KTIcon } from 'src/_config/helpers'

export const InputSelect = ( props ) => {
  // props overnemen
  const [values, setValues] = props.values;
  const label = props.label || 'Label';
  const link = props.link;
  const linkTitle = props.linkTitle || '';
  const placeholder = props.placeholder || "Maak een keuze";
  const name = props.name;
  const cols = props.cols;
  const disabled = props.disabled;
  const onFocus = props.onFocus || null;
  const onBlur = props.onBlur || null;
  const validation = props.validation || "";
  const options = props.options;
  const def = props.def != undefined ? props.def : "";
  const value = getObjectValue( values, name, def );

 
  // classes instellen
  const clsCols = cols ? 'col-' + cols : 'col';
  const cls = validation.includes('required') ? 'required' : '';

  // lokale status
  const [touched, setTouched] = useState< boolean >( false );

  const selectChange = (data) => {
    setTouched( true );
    setValues( setObjectValue( values, name, data, true ) );
  }

  const selectFocus = ( ) => {
    setTouched( true );
    if (onFocus) onFocus();
  }

  // valideren
  const { valid, message } =  fieldValidation( { name, touched, value, validation } );

  return (
    <div className={clsCols}>
      <label className={ cls + ' fw-bold fs-6 mb-2'}>{label}</label>
      {link && link != "off" ?
        <a
          href={link}
          title={linkTitle}
          target='_blank'
          className='btn btn-icon btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='eye' className='fs-1' />
        </a>
      : null }

      <select
        name={name}
        onChange={ (e) => selectChange(e.target.value) }
        className={clsx(
          'form-control form-control-solid mb-3 mb-lg-0',
          {'is-invalid': !valid },
          {
            'is-valid': touched && valid,
          }
        )}
        value={value}
        disabled={ disabled }
        onFocus={ selectFocus }
        onBlur={ onBlur }
      >
      <option value={0}>{placeholder}</option>
        {
          options.map( (item, index) => {
            if (item.enabled == 0) {
              return (
                  <option key={index} disabled value={item.id}>{item.naam}</option>
              )
            }
            else {
              return (
                  <option key={index} value={item.id}>{item.naam}</option>
              )
            }
          })
        }
      </select>
      { touched && !valid && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{message}</span>
          </div>
        </div>
      )}
    </div>
  )
}
